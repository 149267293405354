<template>
  <div>
    <b-navbar toggleable="lg" type="light" class="m-bh-white navbar-fixed" fixed="top" variant="light">
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
         <b-nav-item href="/contact">اتصل بنا</b-nav-item>
         <b-nav-item href="/transactions">الصفقات</b-nav-item>
         <b-nav-item href="/home">الخريطة</b-nav-item>
         <b-nav-item href="/#section_features">الرئيسية</b-nav-item>
         <!-- <b-nav-item href="/real-estate-statistics">الإحصائيات العقارية</b-nav-item> -->
       </b-navbar-nav>
     </b-collapse>
     <b-navbar-brand href="/">
      <img :src="logo" :width="width" />
     </b-navbar-brand>
   </b-navbar>
 </div>
</template>

<script>
export default {
  name: 'Navbar',
  data() {
    return {
      logo: require("@/assets/logo.svg"),
      width: "60px"
    }
  },
  components: {

  },
  methods: {
  },
  mounted() {
  },
  beforeDestroy() {
  }
}
</script>
