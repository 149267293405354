<template>
  <div>
   <navbar-map />
   <router-view></router-view>
   <m-footer />
 </div>
</template>
<script>
import NavbarMap from "@/components/NavbarMap.vue"
import MFooter from "@/components/Footer.vue"

export default {
  components: {
    NavbarMap,
    MFooter
  }
}
</script>
